import React from "react"

import Carousel from "../components/carousel"
import PrimaryBusiness from '../components/primary-business';
import Layout from "../layouts/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="首页" />
    <Carousel />
    <PrimaryBusiness />
  </Layout>
)

export default IndexPage

// 首页：主营业务
import React from "react"
import styled from "styled-components"
import style from "../assets/global-style"
import { Link } from "gatsby"
import { Carousel } from "antd"

import LeftIcon from "../images/left.png"

const Container = styled.div`
  height: 20vh;
  margin: 0 auto;
  margin-top: 15px;
  max-width: 1200px;
  padding-bottom: 15rem;

  .title {
    text-indent: 10px;
    font-size: 19px;
    font-weight: 700;
    color: #231e1d;

    span {
      font-size: 14px;
    }
  }

  .carousel {
    height: 240px;
    width: 100%;

    .item {
      height: 240px;
    }

    .prev-btn {
      position: absolute;
      left: -40px;
      top: 50%;
      transform: translateY(-85%);
      width: 40px;
      height: 40px;
      background: url(${LeftIcon}) center no-repeat;
    }

    .next-btn {
      position: absolute;
      right: -40px;
      top: 50%;
      transform: translateY(-88%) rotate(180deg);
      width: 40px;
      height: 40px;
      background: url(${LeftIcon}) center no-repeat;
    }
  }
`

const Item = styled.div`
  padding: 5px;

  a {
    height: 200px;
    width: 100%;
  }

  & > a:not(:last-child) {
    margin-right: 10px;
  }

  img {
    display: inline-block;
    width: 100%;
    height: 100%;

  }
  ${style.flexBox("row", "space-around")}
`

const data = [
  {
    url: "/business/wuye",
    img: "http://cdn.jerryshi.com/gtkj/20200102110122.jpg",
  },
  {
    url: "/business/getai",
    img: "http://cdn.jerryshi.com/gtkj/20200102110123.jpg",
  },
  {
    url: "/business/brjy",
    img: "http://cdn.jerryshi.com/gtkj/20200102110124.jpg",
  },
  {
    url: "/business/fcjj",
    img: "http://cdn.jerryshi.com/gtkj/20200102110125.jpg",
  },
  {
    url: "/business/jzfw",
    img: "http://cdn.jerryshi.com/gtkj/20200102110128.jpg",
  },
  {
    url: "/business/jdyy",
    img: "http://cdn.jerryshi.com/gtkj/20200102110126.jpg",
  },
  {
    url: "/business/zszx",
    img: "http://cdn.jerryshi.com/gtkj/20200102110129.jpg",
  },
  {
    url: "/business/lhgc",
    img: "http://cdn.jerryshi.com/gtkj/20200102110127.jpg",
  },
]

// N等分数组 [1,2,3,4,5,6] => [[1,2,3], [4,5,6]]
const transformArray = (ary, num = 4) =>
  ary.reduce((memo, curt) => {
    const index = memo.length
    if (index === 0) {
      memo[0] = [curt]
    } else if (memo[index - 1].length === num) {
      memo[index] = [curt]
    } else {
      memo[index - 1].push(curt)
    }
    return memo
  }, [])

const Primary = () => {
  const carRef = React.useRef(null)

  return (
    <Container>
      <p className="title">
        集团业务 <span>Group business</span>
      </p>
      <div className="carousel">
        <span
          role="button"
          tabIndex={0}
          className="prev-btn"
          onClick={() => carRef.current.prev()}
          onKeyDown={() => {}}
        />
        <span
          role="button"
          tabIndex={0}
          className="next-btn"
          onClick={() => carRef.current.next()}
          onKeyDown={() => {}}
        />
        <Carousel dots={false} ref={carRef}>
          {transformArray(data).map((x, idx) => (
            <div key={idx}>
              <Item>
                {x.map(y => (
                  <Link key={y.url} to={y.url} className="b-img">
                    <img key={y.img} src={y.img} alt={idx} />
                  </Link>
                ))}
              </Item>
            </div>
          ))}
        </Carousel>
      </div>
    </Container>
  )
}

export default Primary

import React from "react"
import { Carousel } from "antd"
import styled from "styled-components"

const Container = styled.div`
  img {
    width: 100vw;
    height: 705px;
  }
`

const Swpier = () => (
  <Container>
    <Carousel autoplay>
      <div>
        <img
          src="http://cdn.jerryshi.com/gtkj/20200103120410.jpg"
          alt="loop-1"
        />
      </div>
      <div>
        <img
          src="http://cdn.jerryshi.com/gtkj/20200103120411.jpg"
          alt="loop-2"
        />
      </div>
      <div>
        <img
          src="http://cdn.jerryshi.com/gtkj/20200103120412.jpg"
          alt="loop-3"
        />
      </div>
      <div>
        <img
          src="http://cdn.jerryshi.com/gtkj/20200103120413.jpg"
          alt="loop-4"
        />
      </div>
    </Carousel>
  </Container>
)

export default Swpier
